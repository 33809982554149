<template>
    <div class="h-full ">
        <loader :loading="loading" :backdrop="true"/>

         <div class="flex flex-col mb-12">
            <div class="flex flex-1 justify-between overflow-auto mt-2">
                <p class="font-bold text-lg mb-4 ">recurring charges and credits</p>
                <button
                    class="flex items-center border-b font-bold focus:outline-none mb-4 button-color "
                    type="button"
                    @click="() => this.$router.push({name: 'leases.addRecurring'})"
                    >
                    add new <icon name="plus" class="w-5 h-5 ml-2 color-text" />
                </button>
            </div>
        

            <div class="flex-1 flex-col overflow-auto mb-8">
                <p class="color-text font-bold">charges</p>
                <table class="table-auto w-full" >
                    <thead>
                        <tr>
                            <th>charge code</th>
                            <th>frequency</th>
                            <th>start date</th>
                            <th>end date</th>
                            <th>amount</th>
                            <th>prorate</th>
                            <th>prorated amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="fees.length == 0">
                            no data found
                        </tr>
                        <tr v-else v-for="charge in fees.charges" :key="charge.id">
                            <td>{{charge.name}}</td>
                            <td>{{charge.frequency}}</td>
                            <td>{{charge.start_date}}</td>
                            <td>{{charge.end_date}}</td>
                            <td>{{charge.amount}}</td>
                            <td><input type="checkbox" :checked="charge.prorate ? charge.prorate : false"></td>
                            <td>{{charge.prorate_amount ? charge.prorate_amount : '$0'}}</td>
                        </tr>
                    </tbody>
                  
                </table>
            </div>

            <div class="flex-1 flex-col overflow-auto">
                <p class="color-text font-bold">credits</p>
            
                <table class="table-auto w-full" >
                    <thead>
                        <tr>
                            <th>credit code</th>
                            <th>frequency</th>
                            <th>start date</th>
                            <th>end date</th>
                            <th>amount</th>
                            <th>prorate?</th>
                            <th>prorated amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="fees.length == 0">
                            no data found
                        </tr>
                        <tr v-else v-for="credit in fees.credits" :key="credit.id">
                            <td>{{credit.name}}</td>
                            <td>{{credit.frequency}}</td>
                            <td>{{credit.start_date}}</td>
                            <td>{{credit.end_date}}</td>
                            <td>{{credit.amount}}</td>
                            <td><input type="checkbox" :checked="credit.prorate ? credit.prorate : false"></td>
                            <td>{{credit.prorate_amount ? credit.prorate_amount : '$0'}}</td>
                        </tr>
                    </tbody>
                </table>
                
            </div>
        </div>

         <div class="flex flex-col">
            <div class="flex flex-1 justify-between overflow-auto mt-2">
                <p class="font-bold text-lg mb-4 ">one-time charges and credits</p>
                <button
                    class="flex items-center border-b font-bold focus:outline-none mb-4 button-color "
                    type="button"
                    @click="() => this.$router.push({name: 'leases.addOneTime'})"
                    >
                    add new <icon name="plus" class="w-5 h-5 ml-2 color-text" />
                </button>
            </div>
        

            <div class="flex-1 flex-col overflow-auto mb-8">
                <p class="color-text font-bold">charges</p>
            
                <table class="table-auto w-full" >
                    <thead>
                        <tr>
                            <th>charge code</th>
                            <th>due date</th>
                            <th>post date</th>
                            <th>amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>utility connection charge</td>
                            <td>02/02/2021</td>
                            <td>02/14/2021</td>
                            <td>$780.00</td>
                        </tr>
                        <tr>
                            <td>pet deposit</td>
                            <td>02/05/2021</td>
                            <td>02/10/2021</td>
                            <td>$780.00</td>
                        </tr>
                    </tbody>
                </table>
                
            </div>

            <div class="flex-1 flex-col overflow-auto">
                <p class="color-text font-bold">credits</p>
            
                <table class="table-auto w-full" >
                    <thead>
                        <tr>
                            <th>credit code</th>
                             <th>due date</th>
                            <th>post date</th>
                            <th>amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>credit code</td>
                            <td>02/05/2021</td>
                            <td>02/10/2021</td>
                            <td>$100.00</td>
                        </tr>
                    </tbody>
                </table>
                
            </div>
        </div>
        <modal-footer :footer="footer" :primary="save" :secondary="cancel"/>
    </div>
       

    
</template>

<script>
import AuthMixin from '@/components/auth/AuthMixin'
import NotifyMixin from '@/mixins/NotifyMixin'
import ModalNavigation from '@/mixins/ModalNavigation'
import Loader from '@/components/ui/Loader'
import ModalFooter from '@/components/ui/modals/ModalFooter'
import Icon from "@/components/ui/Icon";
export default {
    mixins: [NotifyMixin, AuthMixin, ModalNavigation,],
    components: {
        Loader,
        Icon,
        ModalFooter
    },
    data: function () {
        return {
            loading: false,
            fees:[],
            footer: {
                primaryButton: 'save & next',
                secondaryButton: 'back',
            },
        };
    },
    methods: {
      fetchResponses() {
        const data = [
          {
            "id": 1001,
            "entity_id": 5001,
            "name": "Jhon Doe",
            "number": "JD1001",
            "type": 1,
            "created_date": "2021-10-05T20:12:31.849Z",
            "updated_date": "2021-10-05T20:12:31.849Z",
            "charges": [
            {
            "id": 1001,
            "entity_id": 5001,
            "name": "rent",
            "description": "rent",
            "amount": 780,
            "is_recurring": true,
            "frequency": "monthly",
            "start_date": "2021-08-01",
            "end_date": "2022-08-01",
            "prorate": false,
            "prorated_amount": null,
            "created_date": "2021-10-05T20:12:31.849Z",
            "updated_date": "2021-10-05T20:12:31.849Z"
            },
            {
            "id": 1002,
            "entity_id": 5001,
            "name": "internet",
            "description": "internet",
            "amount": 780,
            "is_recurring": true,
            "frequency": "monthly",
            "start_date": "2021-08-01",
            "end_date": "2022-08-01",
            "prorate": true,
            "prorated_amount": 390,
            "created_date": "2021-10-05T20:12:31.849Z",
            "updated_date": "2021-10-05T20:12:31.849Z"
            },
            {
            "id": 1003,
            "entity_id": 5001,
            "name": "pet deposit",
            "description": "pet deposit",
            "amount": 780,
            "is_recurring": false,
            "due_date": "2021-10-15",
            "post_date": "2021-10-20",
            "created_date": "2021-10-05T20:12:31.849Z",
            "updated_date": "2021-10-05T20:12:31.849Z"
            }
            ],
            "credits": [
            {
            "id": 1001,
            "entity_id": 5001,
            "name": "rent",
            "description": "rent",
            "amount": 390,
            "is_recurring": true,
            "frequency": "monthly",
            "start_date": "2021-08-01",
            "end_date": "2022-08-01",
            "prorate": false,
            "prorated_amount": null,
            "created_date": "2021-10-05T20:12:31.849Z",
            "updated_date": "2021-10-05T20:12:31.849Z"
            },
            {
            "id": 1002,
            "entity_id": 5001,
            "name": "concessions",
            "description": "concessions",
            "amount": 100,
            "is_recurring": false,
            "due_date": "2021-10-15",
            "post_date": "2021-10-20",
            "created_date": "2021-10-05T20:12:31.849Z",
            "updated_date": "2021-10-05T20:12:31.849Z"
            }
            ]
          },
          {
            "id": 1002,
            "entity_id": 5002,
            "name": "Mark Twain",
            "number": "MT1001",
            "type": 2,
            "created_date": "2021-10-05T20:12:31.849Z",
            "updated_date": "2021-10-05T20:12:31.849Z",
            "charges": [
            {
            "id": 1004,
            "entity_id": 5002,
            "name": "rent",
            "description": "rent",
            "amount": 780,
            "is_recurring": true,
            "frequency": "monthly",
            "start_date": "2021-08-01",
            "end_date": "2022-08-01",
            "prorate": false,
            "prorated_amount": null,
            "created_date": "2021-10-05T20:12:31.849Z",
            "updated_date": "2021-10-05T20:12:31.849Z"
            },
            {
            "id": 1005,
            "entity_id": 5002,
            "name": "internet",
            "description": "internet",
            "amount": 780,
            "is_recurring": true,
            "frequency": "monthly",
            "start_date": "2021-08-01",
            "end_date": "2022-08-01",
            "prorate": true,
            "prorated_amount": 390,
            "created_date": "2021-10-05T20:12:31.849Z",
            "updated_date": "2021-10-05T20:12:31.849Z"
            },
            {
            "id": 1006,
            "entity_id": 5002,
            "name": "pet deposit",
            "description": "pet deposit",
            "amount": 780,
            "is_recurring": false,
            "due_date": "2021-10-15",
            "post_date": "2021-10-20",
            "created_date": "2021-10-05T20:12:31.849Z",
            "updated_date": "2021-10-05T20:12:31.849Z"
            }
            ],
            "credits": [
            {
            "id": 1003,
            "entity_id": 5002,
            "name": "rent",
            "description": "rent",
            "amount": 390,
            "is_recurring": true,
            "frequency": "monthly",
            "start_date": "2021-08-01",
            "end_date": "2022-08-01",
            "prorate": false,
            "prorated_amount": null,
            "created_date": "2021-10-05T20:12:31.849Z",
            "updated_date": "2021-10-05T20:12:31.849Z"
            },
            {
            "id": 1004,
            "entity_id": 5002,
            "name": "concessions",
            "description": "concessions",
            "amount": 100,
            "is_recurring": false,
            "due_date": "2021-10-15",
            "post_date": "2021-10-20",
            "created_date": "2021-10-05T20:12:31.849Z",
            "updated_date": "2021-10-05T20:12:31.849Z"
            }
            ]
          }
        ]
          this.fees = data[0];

        },
        async loadVehicles() {
          await this.$accountsDataProvider.get('getVehicleParty',{party_id: this.item.party_id})      
          .then((response) => {
            this.vehicleInfo = response.data;
            this.vehicleInfo.forEach((vehicle, index) => {
              this.vehicleInfo[index].make = vehicle.vehicle_make
              this.vehicleInfo[index].model = vehicle.vehicle_model
              this.vehicleInfo[index].color = vehicle.vehicle_color
              this.vehicleInfo[index].license_plate = vehicle.vehicle_license_plate_text
            })
          }).catch(() => {
            this.notifyError('There was an error getting the vehicles party ');
          });
        },
        save(){
            this.$router.push({name: 'leases.overview'});
        },
        cancel(){
            this.$router.push({name: 'leases.lease-and-resident', id: this.$route.params.id});
        },
    },
    mounted() {
        this.fetchResponses()
    }
    
}
</script>

<style scoped>
    .color-text{
        color: var(--highlightColor500);
    }

    .button-color{
        border-color: var(--highlightColor500);
        color: var(--highlightColor500);
    }

    tbody tr:hover {
        background-color: transparent;
    }
    tr:nth-child(even) {
        background-color: transparent;
    }
    th {
        padding-left: 0;
        padding-bottom: 0;
        border-bottom: transparent;
    }
    td{
        padding-left: 0;
        padding-bottom: 0;
    }
</style>